
import { defineComponent, ref } from "vue"
import router from "@/router"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const isForgotPasswordLoading = ref(false)
    const rules = ref({
      email: getRule(RuleTypes.MAIL, "E-posta Adresiniz"),
    })
    const forgotPassword = ref({
      email: "",
    })

    function signIn() {
      router.push({ name: "sign-in" })
    }

    function onSubmitForgotPassword(formEl) {
      formEl.validate(async valid => {
        if (valid) {
          isForgotPasswordLoading.value = true
          const config = {}
          try {
            await ApiService.post(
              "auth/forgot-password?email=" + forgotPassword.value.email,
              config
            )
            signIn()
          } catch (error) {
            isForgotPasswordLoading.value = false
          }
        } else {
          isForgotPasswordLoading.value = false
        }
      })
    }

    return {
      onSubmitForgotPassword,
      signIn,
      forgotPassword,
      isForgotPasswordLoading,
      rules,
      ruleFormRef,
    }
  },
})
